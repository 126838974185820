<template>
  <NuxtLink :href="link" :target="blankRedirect">
    <div class="edito-card" :class="getPressReview">
      <TwicImg
        :src="image"
        alt="book-card"
        ratio="none"
        class="image"
        :class="[getPressReview, type]"
      />

      <div v-if="type === 'pressReview'" class="separator" />

      <div class="content" :class="getPressReview">
        <Icon
          v-if="geographicZoneIcon"
          class="icon"
          :name="geographicZoneIcon"
          height="40"
          width="40"
        />
        <div class="wrapper-content">
          <div class="title" :class="type">{{ title }}</div>
          <span v-if="type !== 'eventCatalogue'" class="subtitle" :class="[type, getPressReview]">{{
            subtitle
          }}</span>
          <span v-if="startDate" class="date">
            {{
              formatEventDateISO({
                startDate: startDate,
                endDate: endDate
              })
            }}
            <span v-if="startTime"> à {{ startTime }}</span>
          </span>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="description" v-html="renderMarkdown(description).rendered"></div>
        </div>
        <div class="wrapper-cta" :class="getPressReview">
          <span class="cta" :class="getPressReview">{{ ctaText ?? 'En savoir +' }}</span>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { getGeographicZonePicto } from '@/lib/getGeographicZonePicto';
import { renderMarkdown } from '@/lib/markdown';

const { type, subtitle, title, geographicZoneSlug, countrySlugs } = defineProps<{
  title: string;
  subtitle?: string;
  description: string;
  image: string;
  link: string;
  geographicZoneSlug: string;
  countrySlugs?: string[];
  startDate?: string;
  endDate?: string;
  startTime?: string;
  type: 'article' | 'event' | 'eventCatalogue' | 'pressReview';
  ctaText?: string;
}>();

const geographicZoneIcon = getGeographicZonePicto({ geographicZoneSlug, countrySlugs });

const getPressReview = computed(() => ({ 'press-review': type === 'pressReview' }));

const blankRedirect = type === 'pressReview' ? '_blank' : '';
</script>

<style lang="scss" scoped>
@use '$/border-radius.scss';
@use '$/colors.scss';
@use '$/misc.scss';
@use '$/typography.scss';
@use '$/text-ellipsis.scss';
@use '$/breakpoints.scss';
@use '$/button.scss';

$desktop-width: 416px;
$mobile-width: 350px;

.edito-card {
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 500px;

  background-color: white;
  border: solid 1px colors.$grey-500;
  border-radius: border-radius.$small;

  &.press-review {
    flex-direction: row;

    width: 100%;
    min-height: 200px;

    border: none;
    box-shadow: 2px 2px 21px 2px colors.$grey-300;
  }

  .image {
    @include misc.twic-pics-border-radius-top-left(4px);
    @include misc.twic-pics-border-radius-top-right(4px);

    width: 100%;
    height: 150px;
    min-height: 150px;

    &.event {
      :deep(.twic-w) {
        border-bottom: solid 4px colors.$gold;
      }
    }

    &.article {
      :deep(.twic-w) {
        border-bottom: solid 4px colors.$gold;
      }
    }

    &.press-review {
      flex-shrink: 0.8;
      width: 100%;
      max-width: 200px;
      padding: 20px 24px 20px 20px;

      :deep(.twic-w) {
        border-bottom: none;
      }
    }
  }

  .separator {
    flex-shrink: 0;
    align-self: center;

    width: 2px;
    height: 100%;
    max-height: 120px;

    background-color: colors.$gold;
  }

  .content {
    position: relative;

    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    padding: 32px 12px 12px;

    &.press-review {
      padding: 20px 20px 20px 24px;
    }

    .icon {
      position: absolute;
      top: -32px;
      left: 0;
      left: calc(50% - 32px);
      transform: translateZ(0) !important; // remove safari flickering

      justify-content: space-between;

      width: 64px;
      height: 64px;
      padding: 12px;

      background-color: white;
      border-radius: 50%;
    }

    .wrapper-content {
      flex-direction: column;

      .title {
        @include text-ellipsis.multi-line(4);

        margin-bottom: 6px;
        font-size: typography.$desktop-h4;
        font-weight: 600;
        line-height: 26px;

        &.article {
          color: colors.$green;
        }

        &.event {
          margin-bottom: 18px;
          color: colors.$black;
        }
      }

      .subtitle {
        margin-bottom: 12px;
        font-size: 18px;
        color: colors.$gold;

        &.event {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          text-transform: capitalize;
        }

        &.press-review {
          font-size: 14px;
        }
      }

      .date {
        font-weight: 600;
      }

      .description {
        @include text-ellipsis.multi-line(4);

        line-height: 20px;
        color: colors.$black;
      }
    }

    .wrapper-cta {
      justify-content: center;
      height: 90px;

      &.press-review {
        justify-content: flex-start;
      }

      .cta {
        @include button.outlined();

        align-self: center;

        &.press-review {
          align-self: flex-end;
        }
      }
    }
  }
}

/* stylelint-disable-next-line order/order */
@include breakpoints.mobile() {
  .edito-card {
    max-width: $mobile-width;

    &.press-review {
      flex-direction: column;
    }

    .image {
      height: 176px;
      min-height: 176px;

      &.press-review {
        flex-shrink: 0.8;
        align-self: center;
        max-width: 100%;
        padding: 29px 20px 23px;
      }
    }

    .separator {
      width: 90%;
      padding-top: 2px;
    }

    .content {
      padding: 28px 16px 24px;

      &.press-review {
        padding: 23px 20px 29px;
      }

      .title {
        font-size: 18px;
      }

      .description {
        @include text-ellipsis.multi-line(4);

        font-size: typography.$mobile-body-l;
      }
    }
  }
}
</style>
